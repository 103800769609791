import { Component } from 'vue-property-decorator'
import GtrBadgesModuleIndexView from '..'
import { mapState } from 'vuex'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GtrSelectSizeTab from './tab-components/select-size/select-size.view.vue'
import GtrBadgeListTab from './tab-components/badge-list/badge-list.view.vue'
import GtrBadgesBadgeFormModal from './badge-form/badge-form.view.vue'
import { BadgeFormOptions, RegistrationType, HubspotFormOptions } from '@/modules/level-two/models/badges.model'
import HubspotFormModal from './hubspot-form/hubspot-form.view.vue'
import GtrSvgBuilder from '@/modules/common/components/ui-core/gtr-svg-builder/gtr-svg-builder.vue'

@Component({
  name: 'GtrUploadEditBadgesView',
  computed: {
    ...mapState('event', ['event_stats', 'registration_type_counts']),
    ...mapState('badges', ['badges', 'registrationTypes']),
    ...mapState('formbuilder', ['eventFields']),
    ...mapState('option', ['option_groups'])
  },
  components: {
    'select-size-tab': GtrSelectSizeTab,
    'badge-list-tab': GtrBadgeListTab,
    'badge-form-modal': GtrBadgesBadgeFormModal,
    'hubspot-form-modal': HubspotFormModal,
    'gtr-svg-builder': GtrSvgBuilder
  }
})
export default class GtrUploadEditBadgesView extends GtrBadgesModuleIndexView {
  badges!: Array<Record<string, any>>;

  registrationTypes!: Array<RegistrationType>;

  event_stats!: Record<string, any>;

  registration_type_counts!: Array<Record<string, any>>;

  eventFields!: Array<Record<string, any>>;

  option_groups!: Array<Record<string, any>>;

  data () {
    return {
      dataLoaded: true,
      currentTab: 0,
      tabs: [
        {
          name: 'Select Size',
          component: 'select-size-tab'
        },
        {
          name: 'Badge List',
          component: 'badge-list-tab'
        }
      ],
      showBadgeFormModal: false,
      badgeFormModalOptions: {},
      showHubspotFormModal: false,
      hubspotFormModalOptions: {},
      showSvgBuilderModal: false,
      svgSize: null,
      badgeObj: null
    }
  }

  async mounted () {
    try {
      this.$store.dispatch('common/showLoader', { value: true })
      await this.fetchEventStats()
      await this.fetchRegistrationTypeCounts()
      await this.fetchEventFields()
      await this.fetchOptionGroups()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$store.dispatch('common/hideLoader')
    }
  }

  async fetchBadges () {
    await this.$store.dispatch('badges/fetchBadges', { event_uuid: this.$route.params.event_uuid })
  }

  async fetchEventStats () {
    await this.$store.dispatch('event/getEventStats', this.$route.params.event_uuid)
  }

  async fetchRegistrationTypeCounts () {
    await this.$store.dispatch('event/getRegistrationTypeCounts', { event_uuid: this.$route.params.event_uuid })
  }

  async fetchEventFields () {
    await this.$store.dispatch('formbuilder/getEventFields', { event_uuid: this.$route.params.event_uuid })
  }

  async fetchOptionGroups () {
    await this.$store.dispatch('option/getOptionsGroup', { event_uuid: this.$route.params.event_uuid })
  }

  get registrationTypesAndBadges (): RegistrationType[] {
    const regTypesAndBadges = {}

    if (Array.isArray(this.badges)) {
      this.badges.forEach((badge) => {
        if (badge.registration_type) {
          regTypesAndBadges[badge.registration_type] = badge.uuid
        }
      })
    }

    // Add badge UUIDs to reg types if they have been assigned.
    if (Array.isArray(this.registrationTypes)) {
      return this.registrationTypes.reduce(
        function (regTypes: RegistrationType[], regTypeObj: RegistrationType): RegistrationType[] {
          const regType: RegistrationType = {
            name: regTypeObj.name,
            uuid: regTypeObj.uuid,
            visible: regTypeObj.visible,
            badge_uuid: regTypesAndBadges[regTypeObj.uuid] || null
          }
          regTypes.push(regType)
          return regTypes
        }, []
      )
    }

    return []
  }

  handleOpenBadgeFormModal (options: BadgeFormOptions) {
    this.$data.badgeFormModalOptions = options
    this.$data.showBadgeFormModal = true
  }

  async handleBadgeSubmitted () {
    try {
      this.$store.dispatch('common/showLoader', { value: true })
      await this.fetchBadges()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$store.dispatch('common/hideLoader')
    }
  }

  handleCloseBadgeFormModal () {
    this.$data.showBadgeFormModal = false
    setTimeout(() => { this.$data.badgeFormModalOptions = {} }, 300)
  }

  handleOpenHubspotFormModal (options: HubspotFormOptions) {
    this.$data.hubspotFormModalOptions = options
    this.$data.showHubspotFormModal = true
  }

  handleCloseHubspotFormModal () {
    this.$data.showHubspotFormModal = false
    setTimeout(() => { this.$data.hubspotFormModalOptions = {} }, 300)
  }

  handleOpenSvgBuilderModal (size: string, badge: Record<string, any>) {
    if (size) {
      this.$data.svgSize = size
    }
    if (badge) {
      this.$data.badgeObj = badge
    }
    this.$data.showSvgBuilderModal = true
  }

  handleCloseSvgBuilderModal () {
    this.$data.badgeObj = null
    this.$data.showSvgBuilderModal = false
  }
}
