import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import BadgesMixin from '@/modules/level-two/views/event/modules/badges/mixins/badges.mixin'
import { mapState } from 'vuex'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { BadgeFormModes, BadgeFormMode, BadgeFormOptions } from '@/modules/level-two/models/badges.model'

@Component({
  name: 'GtrBadgesBadgeList',
  computed: {
    ...mapState('badges', ['badges'])
  }
})
export default class GtrBadgesBadgeList extends mixins(GtrSuper, BadgesMixin) {
  badges!: Array<Record<string, any>>;

  BadgeFormModes = BadgeFormModes

  previewData = false;

  data () {
    return {
      previewModal: {
        svg: '',
        dataPreview: '',
        title: '',
        regType: '',
        isOpen: false
      }
    }
  }

  async toggleDataPreview (e: MouseEvent): Promise<void> {
    if (!this.$data.previewModal.dataPreview) {
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        reg_type: this.$data.previewModal.regType
      }
      const rando = await this.$store.dispatch('attendee/fetchRandomAttendee', payload)
      if (!rando || !rando.participant_data || !rando.selected_options) {
        this.$data.previewModal.dataPreview = ''
      } else {
        this.$data.previewModal.dataPreview = this.buildDataPreview(this.$data.previewModal.svg, rando.participant_data, rando.selected_options)
      }
    }
    this.previewData = !this.previewData
  }

  openBadgePreviewModal (index: number) {
    this.$data.previewModal.svg = this.getSvgPreview(this.badges[index].svg)
    this.$data.previewModal.title = this.badges[index].name
    this.$data.previewModal.regType = this.badges[index].registration_type
    this.$data.previewModal.isOpen = true
  }

  handleClosePreviewModal () {
    this.$data.previewModal.isOpen = false
    setTimeout(() => {
      this.previewData = false
      this.$data.previewModal.svg = ''
      this.$data.previewModal.title = ''
      this.$data.previewModal.regType = ''
      this.$data.previewModal.dataPreview = ''
    }, 300)
  }

  openBadgeFormModal (mode: BadgeFormMode, index: number, active?: 0 | 1) {
    let title = ''
    if (mode === BadgeFormModes.EDIT) {
      title = 'Edit Badge'
    } else if (mode === BadgeFormModes.DUPLICATE) {
      title = 'Duplicate Badge'
    }
    const badgeFormOptions: BadgeFormOptions = {
      mode: mode,
      title: title,
      badge: this.badges[index]
    }
    if (active === 0 || active === 1) {
      badgeFormOptions.active = active
    }
    this.$emit('openBadgeFormModal', badgeFormOptions)
  }

  async deactivateBadge (index: number) {
    try {
      this.$store.dispatch('common/showLoader', { value: true })
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        badge_uuid: this.badges[index].uuid,
        data: {
          active: 0,
          registration_type: null
        }
      }
      await this.$store.dispatch('badges/updateBadge', payload)
      await this.$store.dispatch('badges/fetchBadges', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$store.dispatch('common/hideLoader')
    }
  }

  openInSvgEditor (index: number) {
    const badge = this.badges[index]
    if (!badge.svg.includes('gtr-svg-editor') && !badge.svg.includes('canvas')) {
      Container.get(Notification).error('This badge was not created with GTR Badge Editor.')
      return
    }
    if (badge.svg.includes('gtr-svg-editor') || badge.svg.includes('canvas')) {
      const badgeSize = badge.badge_properties.size
      this.$emit('openSvgBuilderModal', badgeSize, badge)
    }
  }
}
